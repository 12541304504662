.questionStructure {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.questionSubStructure {
   min-height: 950px;
   width: 90%;
   /* border: 2px solid blue; */

   padding: 0 5%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
   
.quesHead {
   text-align: center;
   font-size: 28px;
   line-height: 40px;
}

.submitBtn {
   width: 300px;
   padding: 10px 0;
   border-radius: 16px;
   cursor: pointer;
   color: white;
   text-align: center;
   font-weight: 600;
   font-size: 24px;
   letter-spacing: 1.1px;
   background-color: #162d3a;
}
