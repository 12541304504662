.TabletContainer {
   height: 100%;
   width: 100%;
   /* border: 3px solid red; */
}

.Container {
   height: 100vh;
   width: 100%;
   display: flex;
   justify-content: center;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
