.quesImageBefore {
   width: 100%;
   height: auto;
   margin-bottom: 40px;
}
.quesImageAfter {
   width: 80%;
   display: flex;
   justify-self: center;
   height: auto;
   border: 1px solid #cacaca;
   border-radius: 10px;
}

.quesOptionContainer {
   display: flex;
   margin-top: 30px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.rowContainer {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 0;
}

.optionContainer {
   position: relative;
   width: 260px;
   margin-top: 30px;
}
.optionContainer:not(:nth-child(odd)) {
   margin-left: 28px;
}

.option {
   font-size: 88px;
   border: 1px solid #d6d6d6;
   background-color: #fffcf1;
   border-radius: 16px;
   color: #6a4a35;
}

.chooseOption {
   position: absolute;
   top: 0;
   opacity: 0;
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.optionActive {
   border-radius: 32px;
   border: 3px solid #008000 !important;
}

.audioContainer {
   display: flex;
   align-items: center;
}

.audioContainer > span {
   font-size: 28px;
   font-weight: 600;
}

.speaker {
   font-size: 64px;
   color: white;
   background-color: #6a4a35;
   padding: 10px;
   border-radius: 10px;
   cursor: pointer;
}
