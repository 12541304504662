.s5optionContainer {
   min-height: 800px;
   width: 100%;
   margin: 32px 0;

   display: grid;
   gap: 10px;
   /* grid-template-rows: repeat(5, 1fr);
   grid-auto-columns: minmax(100px, auto); */
}

.s5option {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.s5option > div {
   min-height: 80px;
   min-width: 80px;
   max-width: 180px;
   max-height: 180px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.s5option:nth-child(2) > div {
   width: 120px;
   height: 120px;
}
.s5option:nth-child(3) > div {
   width: 120px;
   height: 120px;
}
.s5option:nth-child(6) > div {
   width: 120px;
   height: 120px;
}
.s5option:nth-child(7) > div {
   width: 120px;
   height: 120px;
}
.s5option:nth-child(10) > div {
   width: 120px;
   height: 120px;
}

.selected {
   width: 100%;
   height: 100%;
   border: 4px solid #008000;
   border-radius: 16px;
}

.unselected {
   width: 100%;
   height: 100%;
   border: 4px solid white;
   border-radius: 16px;
}
