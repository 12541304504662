.quesImage {
   width: 100%;
   height: auto;
}

.s2OptionImage {
   margin: 32px 0;
   height: 350px;
   width: auto;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
}

.s2OptionImage img {
   height: 100%;
}

.options {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   /* background-color: red; */
}

.egg {
   width: 55px;
   margin: 22px;
   cursor: pointer;
}

.egg:nth-child(even) {
   margin-left: 84px;
}

/* .audioQues {
   width: 100%;
   height: 20px;
   background-color: red;
}

.speaker {
   font-size: 64px;
   color: white;
   background-color: #6a4a35;
   padding: 10px;
   border-radius: 10px;
   cursor: pointer;
} */
